




















import {Component, Prop, Vue} from 'vue-property-decorator';
import Modal from '@/components/Modal.vue';

@Component({
  components: {
    Modal,
  },
})
export default class BecomeModel extends Vue {
  @Prop({default: 'Learn more'}) readonly buttonText!: string;

  showPopup = false;

  switchPopups() {
    this.showPopup = false;
    this.$emit('showRegistration');
  }
}
