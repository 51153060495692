import { render, staticRenderFns } from "./ComingSoonModal.vue?vue&type=template&id=1d97093c&scoped=true&lang=pug&"
import script from "./ComingSoonModal.vue?vue&type=script&lang=ts&"
export * from "./ComingSoonModal.vue?vue&type=script&lang=ts&"
import style0 from "./ComingSoonModal.vue?vue&type=style&index=0&id=1d97093c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d97093c",
  null
  
)

export default component.exports