






































































































































































import {Component, Mixins, Prop} from 'vue-property-decorator';
import DropDown from './DropDown.vue';
import PostMenu from '@/components/modals/PostMenu.vue';
import NumbersMixin from '@/mixins/NumbersMixin';
import {AffiliateContentResponse} from '@/types/AffiliateContentResponse';
import MomentDateMixin from '@/mixins/MomentDateMixin';
import VideoPreviewMixin from '@/mixins/VideoPreviewMixin';
import {PostMenuParamsInterface} from '@/types/PostMenuParamsInterface';
import VideoPlayer from '@/components/VideoPlayer.vue';
import DefaultAvatarMixin from '@/mixins/DefaultAvatarMixin';
import {ModelDataInterface} from '@/types/userDataInterface';
import {VideoInterface} from '@/types/videoInterface';
import {PhotoInterface} from '@/types/photoInterface';
import {MediaEnum} from '@/types/enums/MediaEnum';
import {vxm} from '@/store';
import CommentsModal from '@/components/modals/CommentsModal.vue';
import ComingSoonModal from '@/components/modals/ComingSoonModal.vue';
import PostDetailsModal from '@/components/modals/PostDetailsModal.vue';
import ManageSubscriptionsModal from '@/components/modals/ManageSubscriptionModal/ManageSubscriptionsModal.vue';
import LoginOrRegisterModal from '@/components/modals/LoginOrRegisterModal.vue';
import ChoiceCardModal from '@/components/modals/ChoiceCardModal.vue';
import CCBillModal from '@/components/modals/CCBillModal.vue';
import CCBillMessagesMixin from '@/mixins/CCBillMessagesMixin';
import TipsToModel from '@/components/modals/TipsToModel.vue';
import GeneralApi from '@/service/generalApi';

@Component({
  components: {
    PostMenu,
    DropDown,
    VideoPlayer,
    CommentsModal,
    ManageSubscriptionsModal,
    LoginOrRegisterModal,
    CCBillModal,
    ChoiceCardModal,
    TipsToModel,
    ComingSoonModal,
    PostDetailsModal,
  },
})
export default class PostCardNew extends Mixins(
  NumbersMixin,
  MomentDateMixin,
  VideoPreviewMixin,
  DefaultAvatarMixin,
  CCBillMessagesMixin,
) {
  mediaEnum = MediaEnum;
  showCommentsModal = false;
  processing = false;
  processingLike = false;
  showSubscriptionModal = false;
  showLoginModal = false;
  showComingSoon = false;
  showCardsModal = false;
  showCCBillModel = false;
  showDetailModal = false;
  tipsToModel = false;
  @Prop(Object) readonly data!: AffiliateContentResponse | VideoInterface | PhotoInterface;
  @Prop() readonly view!: 'grid' | 'row';
  @Prop(Object) readonly model!: ModelDataInterface;
  @Prop({default: true}) readonly withAvatar!: boolean;
  @Prop({default: false}) readonly isSubscribed!: boolean;
  @Prop({default: false}) readonly showHeader!: boolean;
  @Prop({default: false}) readonly showDescription!: boolean;
  @Prop({default: false}) readonly showFeaturedHeader!: boolean;
  @Prop({default: false}) readonly showFeedsOverlay!: boolean;
  @Prop({default: false}) readonly showVidsOverlay!: boolean;
  @Prop({default: false}) readonly showPremiumDescription!: boolean;
  hideMyPhotosHeader = false;
  hideAvatar = true;
  isReDirectTo = false;
  @Prop(String) feedClass!: string;

  get isLogin(): boolean {
    return !!vxm.user.token;
  }

  get isMy(): boolean {
    return this.data.performer.findIndex((x) => x._id === vxm.user.data._id) !== -1;
  }

  get isPerformer(): boolean {
    return vxm.user.role === 'performer';
  }

  blurredImg(): string {
    return require(`@/assets/images/logo-login.svg`);
  }

  filterPostMenuData(imageData: AffiliateContentResponse): PostMenuParamsInterface {
    // TODO have no types to return have an error here
    return {
      username: this.model.username,
      description: imageData.description || '',
      linkId: imageData._id,
      modelId: this.model._id,
    };
  }

  showDetails() {
    if (
      (this.data.mediaType === MediaEnum.CLIPS && (this.data as AffiliateContentResponse).isViewable) ||
      (this.data.mediaType === MediaEnum.PICS && (this.isSubscribed || this.isMy)) ||
      (this.data.mediaType === MediaEnum.PICS_PLUS && (!(this.data as PhotoInterface).isPurchasable || this.isMy)) ||
      (this.data.mediaType === MediaEnum.VIDS && (this.isSubscribed || this.isMy)) ||
      (this.data.mediaType === MediaEnum.VIDS_PLUS && ((this.data as VideoInterface).isPurchased || this.isMy))
    ) {
      this.showDetailModal = true;
    } else if (this.data.mediaType === MediaEnum.VIDS || this.data.mediaType === MediaEnum.PICS) {
      if (this.model.isPayable) {
        this.showSubscriptionModal = true;
      } else {
        this.showComingSoon = true;
      }
    } else if (this.data.mediaType === MediaEnum.VIDS_PLUS || this.data.mediaType === MediaEnum.PICS_PLUS) {
      this.data.mediaType === MediaEnum.VIDS_PLUS ? this.buyVideo(this.data) : this.buyAlbum(this.data);
    } else if (this.data.mediaType === MediaEnum.CLIPS && !(this.data as AffiliateContentResponse).isViewable) {
      this.showSubscriptionModal = true;
    }
  }

  showTipModal() {
    if (this.model && this.model.isPayable) {
      this.tipsToModel = true;
    } else {
      this.showComingSoon = true;
    }
  }

  async buyAlbum(data) {
    if (this.model && this.model.isPayable) {
      const results = await GeneralApi.directaRegionCheck();
      if (results.data.is_redirect_to_directa) {
        const loginUserData = await vxm.user.getMe();
        const nameArray = loginUserData.data.name.split(' ');
        const firstName = nameArray[0];
        const lastName = nameArray.slice(1).join(' ');
        const finalLastName = lastName || firstName;

        const DirectaObj = {
          amount: data.salePrice,
          country: results.data.country_code,
          currency: 'USD',
          photoId: data._id,
          videoId: null,
          payer: {
            firstName: firstName,
            lastName: finalLastName,
            email: loginUserData.data.email,
          },
          currentPagePath: this.$route.path,
          performerId: data.performer[0]._id,
          userId: loginUserData.data._id,
          description: 'Purchase Premium Photo',
          saleType: 'sale_photo',
        };

        vxm.user
          .directaPayment(DirectaObj)
          .then((res) => {
            window.location.href = res.data.redirect_url;
          })
          .catch((error) => {
            return error;
          });
      } else {
        if (vxm.user.userCards && vxm.user.userCards.length) {
          this.showCardsModal = true;
        } else {
          vxm.user
            .paymentPhoto({
              photoId: this.data._id,
              provider: 'ccbill',
            })
            .then(() => {
              // this.showCCBillModel = true;
              setTimeout(() => {
                window.open(vxm.user.CCBillFormUrl, '_blank');
              });
            })
            .catch((error) => {
              return error;
            });
        }
      }
    } else {
      this.showComingSoon = true;
    }
  }

  timeConvert(duration: number): string {
    let min = Math.floor(duration / 60) + '';
    let sec = duration - Math.floor(duration / 60) * 60 + '';
    min = min.length === 1 ? '0' + min : min;
    sec = sec.length === 1 ? '0' + sec : sec;
    return `${min}:${sec}`;
  }

  async favorite() {
    if (!this.processing) {
      this.processing = true;
      const api = (this.data as VideoInterface).isMyFavorite ? vxm.fan.removeFromFavorite : vxm.fan.addToFavorite;
      await api({
        entityId: this.data._id,
        entityType:
          this.data.mediaType === MediaEnum.PICS || this.data.mediaType === MediaEnum.PICS_PLUS ? 'photo' : 'video',
      })
        .then(() => {
          this.favoriteChanged();
        })
        .catch((error) => {
          return error;
        });
      this.processing = false;
    }
  }

  favoriteChanged() {
    (this.data as VideoInterface).isMyFavorite = !(this.data as VideoInterface).isMyFavorite;
  }

  like() {
    if (!this.processingLike) {
      this.processingLike = true;
      const api = (this.data as VideoInterface).isLikedByMe ? vxm.fan.unlike : vxm.fan.like;
      api({
        entityId: this.data._id,
        entityType:
          this.data.mediaType === MediaEnum.PICS || this.data.mediaType === MediaEnum.PICS_PLUS ? 'photo' : 'video',
      })
        .then((res) => {
          this.processingLike = false;
          if (res.data.ok) {
            this.likeChanged();
          }
        })
        .catch((error) => {
          this.processingLike = false;
          return error;
        });
    }
  }

  likeChanged() {
    (this.data as VideoInterface).isLikedByMe
      ? (this.data as VideoInterface).likeTotal--
      : (this.data as VideoInterface).likeTotal++;
    (this.data as VideoInterface).isLikedByMe = !(this.data as VideoInterface).isLikedByMe;
  }

  goTag(item: VideoInterface) {
    if (item.tags[0]) {
      this.$router.push(`/fan/search/${item.tags[0]}/videos`);
    }
  }

  showComments(item: VideoInterface) {
    this.showCommentsModal = !this.showCommentsModal;
  }

  goToProfile() {
    this.$router.push(`/${this.model.username}`);
  }

  async buyVideo(data) {
    if (this.model && this.model.isPayable) {
      const results = await GeneralApi.directaRegionCheck();
      if (results.data.is_redirect_to_directa) {
        const loginUserData = await vxm.user.getMe();
        const nameArray = loginUserData.data.name.split(' ');
        const firstName = nameArray[0];
        const lastName = nameArray.slice(1).join(' ');
        const finalLastName = lastName || firstName;

        const DirectaObj = {
          amount: data.price,
          country: results.data.country_code,
          currency: 'USD',
          videoId: data._id,
          photoId: null,
          payer: {
            firstName: firstName,
            lastName: finalLastName,
            email: loginUserData.data.email,
          },
          currentPagePath: this.$route.path,
          performerId: data.performer[0]._id,
          userId: loginUserData.data._id,
          description: 'Purchase Premium Video',
          saleType: 'sale_video',
        };
        vxm.user
          .directaPayment(DirectaObj)
          .then((res) => {
            window.location.href = res.data.redirect_url;
          })
          .catch((error) => {
            return error;
          });
      } else {
        if (vxm.user.userCards && vxm.user.userCards.length) {
          this.showCardsModal = true;
        } else {
          vxm.user
            .paymentVideo({
              videoId: this.data._id,
              provider: 'ccbill',
              // transactionId: this.defaultCard,
            })
            .then(() => {
              setTimeout(() => {
                window.open(vxm.user.CCBillFormUrl, '_blank');
              });
              // this.showCCBillModel = true;
            })
            .catch((error) => {
              return error;
            });
        }
      }
    } else {
      this.showComingSoon = true;
    }
  }

  selectedPayment(id: string) {
    const paymentOptions: any = {
      provider: 'ccbill',
    };
    paymentOptions[this.data.mediaType === MediaEnum.PICS_PLUS ? 'photoId' : 'videoId'] = this.data._id;
    if (id) {
      paymentOptions.transactionId = id;
    }
    const api = this.data.mediaType === MediaEnum.PICS_PLUS ? vxm.user.paymentPhoto : vxm.user.paymentVideo;
    GeneralApi.directaRegionCheck().then((res) => {
      this.isReDirectTo = res.data.is_redirect_to_directa;
    });
    if (this.isReDirectTo) {
      // Directa API call here
    } else {
      api(paymentOptions)
        .then(() => {
          if (!paymentOptions.transactionId) {
            // this.showCCBillModel = true;
            setTimeout(() => {
              window.open(vxm.user.CCBillFormUrl, '_blank');
            });
            this.showCardsModal = false;
          } else {
            this.onSuccessRedirectUrl = null;
            if (this.data.mediaType === 'vids+') {
              this.onSuccessRedirectUrl = '/movie/' + this.data._id;
            } else if (this.data.mediaType === 'pics+') {
              this.onSuccessRedirectUrl = '/photo/' + this.data._id;
            } else if (this.data.mediaType === 'vids' || this.data.mediaType === 'pics') {
              this.onSuccessRedirectUrl = `/${this.model.username}/${this.data.mediaType}`;
            }

            window.addEventListener('message', this.receiveMessage);
            this.hiddenIframe = document.createElement('iframe');
            this.hiddenIframe.setAttribute('src', vxm.user.CCBillFormUrl);
            document.body.appendChild(this.hiddenIframe);
            (this.data as VideoInterface).isPurchased = true;
          }
        })
        .catch(() => {
          this.showCardsModal = false;
        });
    }
  }
}
