





































import ClickOutside from '@/directives/clickOutside';
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  directives: {ClickOutside},
})
export default class VideoPlayer extends Vue {
  videoPlayer: HTMLVideoElement | null = null;
  duration = 0;
  currentTime = 0;
  isFullscreen = false;
  volume = 0;
  showVolume = false;
  showSettings = false;
  selectedResolution = 720;
  presetTime = 0;
  mouseOver = false;
  forceS3 = false;
  containerWidth = 0;
  containerHeight = 0;
  isPiPSupport = false;
  loading = false;
  isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
  isIOS = /iPad|iPhone|iPod/.test(navigator.platform);
  videoWorks = false;
  videoHeight = 0;
  videoWidth = 0;
  metadataLoaded = false;
  @Prop(String) readonly poster!: string;
  @Prop(Number) readonly height!: number;
  @Prop(Number) readonly width!: number;
  @Prop({type: Boolean, default: false}) readonly preload!: string;
  @Prop({type: Boolean, default: true}) readonly controls!: boolean;
  @Prop({default: []}) readonly sources!: {height: number; path: string; type: string}[];
  @Prop(String) readonly type!: string;

  get formattedDuration(): string {
    return this.formatTime(this.duration);
  }

  get formattedCurrentTime(): string {
    return this.formatTime(this.currentTime);
  }

  get progressPosition(): string {
    return `width: ${(this.currentTime / this.duration) * 100}%`;
  }

  get getPath() {
    const source = this.sources.find((x) => x.height === this.selectedResolution);
    const path = source ? source.path : this.sources[0].path;
    return this.forceS3 ? path.replace('?', '?forceS3=true&') : path;
  }

  get containerSize() {
    // return `height: ${this.height ? this.height : (this.containerWidth / 16) * 9}px; ${
    //   this.width ? `width: ${this.width}px` : ''
    // }}`;

    if (this.metadataLoaded) {
      if (this.videoHeight > this.videoWidth) {
        const innerHeight = window.innerHeight;
        return `height: ${
          this.videoHeight > innerHeight ? innerHeight - 100 : this.videoHeight / 1.5
        }px;  width: 100%;`;
      } else {
        return `height:  ${
          this.containerHeight ? this.containerHeight : (this.containerWidth / 16) * 9
        }px; width: auto;`;
      }
    }

    return `height:  ${this.containerHeight ? this.containerHeight : (this.containerWidth / 16) * 9}px; width: 100%;`;
  }

  get vidoElementSize() {
    if (this.containerHeight === 0 && this.containerWidth === 0) {
      return `height: 100%; width: 100%;`;
    }

    if (this.containerHeight > this.containerWidth) {
      return `height:  ${this.containerHeight ? this.containerHeight : (this.containerWidth / 16) * 9}px; width: 100%;`;
    } else {
      return `height:  ${this.containerHeight ? this.containerHeight : (this.containerWidth / 16) * 9}px; width: auto;`;
    }
  }

  mounted() {
    this.initPlayer();
  }

  initPlayer() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    const time = Date.now();
    this.videoPlayer = this.$refs.videoPlayer as HTMLVideoElement;
    this.videoPlayer.addEventListener(
      'loadedmetadata',
      () => {
        // retrieve dimensions
        this.metadataLoaded = true;
        if (this.videoPlayer) {
          this.videoHeight = this.videoPlayer.videoHeight;
          this.videoWidth = this.videoPlayer.videoWidth;
        }
      },
      false,
    );
    this.volume = this.videoPlayer.volume;
    if ('pictureInPictureEnabled' in document) {
      this.isPiPSupport = !!this.videoPlayer.requestPictureInPicture;
    }
    this.videoPlayer.onloadstart = function() {
      self.$emit('onReady');
    };
    this.videoPlayer.ontimeupdate = function() {
      if (self.presetTime && self.videoPlayer) {
        self.videoPlayer.currentTime = self.presetTime;
        self.presetTime = 0;
        self.play();
      }
      self.currentTime = self.videoPlayer?.currentTime as number;
    };
    this.videoPlayer.ondurationchange = function() {
      self.duration = self.videoPlayer?.duration as number;
    };
    this.videoPlayer.onclick = function() {
      self.play();
    };
    this.videoPlayer.onpause = function() {
      self.$emit('onPause');
    };
    this.videoPlayer.onplay = function() {
      self.$emit('onPlay');
      if (60 * 60 * 1000 <= Date.now() - time) {
        self.$emit('reloadFile');
      }
      self.$gtag.event('play', {
        // eslint-disable-next-line @typescript-eslint/camelcase
        event_category: 'video',
        // eslint-disable-next-line @typescript-eslint/camelcase
        event_label: self.type,
      });
      self.$emit('onFirstFrame');
    };
    this.videoPlayer.onerror = function() {
      if (!self.forceS3) {
        self.forceS3 = true;
      }
      self.$gtag.event('error', {
        // eslint-disable-next-line @typescript-eslint/camelcase
        event_category: 'video',
        // eslint-disable-next-line @typescript-eslint/camelcase
        event_label: self.type,
      });
    };
    this.videoPlayer.onwaiting = function() {
      self.loading = true;
    };
    this.videoPlayer.onleavepictureinpicture = function() {
      self.videoPlayer?.pause();
    };
    this.videoPlayer.onplaying = function() {
      self.loading = false;
    };
    this.videoPlayer.onended = function() {
      self.$gtag.event('complete', {
        // eslint-disable-next-line @typescript-eslint/camelcase
        event_category: 'video',
        // eslint-disable-next-line @typescript-eslint/camelcase
        event_label: self.type,
      });
    };
    document.addEventListener('fullscreenchange', (event) => {
      this.isFullscreen = !!document.fullscreenElement;
    });
    this.$nextTick(() => {
      this.containerWidth = (this.$refs.videoContainer as HTMLElement).offsetWidth;
      this.containerHeight = (this.$refs.videoContainer as HTMLElement).offsetHeight;
      const resizeObserver = new ResizeObserver(function() {
        const container = self.$refs.videoContainer as HTMLElement;
        if (container) {
          // eslint-disable-next-line no-debugger
          // debugger
          self.containerWidth = container.offsetWidth;
          self.containerHeight = container.offsetHeight;
        }
      });
      resizeObserver.observe(this.$refs.videoContainer as Element);
    });
  }

  changeQuality(resolution: number) {
    this.selectedResolution = resolution;
    this.presetTime = this.currentTime;
    this.showSettings = false;
  }

  onVolChange() {
    if (this.videoPlayer) {
      this.videoPlayer.volume = this.volume;
    }
  }

  play() {
    if (document.pictureInPictureElement) {
      document.exitPictureInPicture();
      this.videoPlayer?.requestPictureInPicture();
      this.videoPlayer?.play();
    } else {
      if (this.videoPlayer?.paused || this.videoPlayer?.ended) {
        this.videoPlayer?.play();
      } else {
        this.videoPlayer?.pause();
      }
    }
  }

  seek(time: number) {
    if (this.videoPlayer) {
      this.videoPlayer.currentTime += time;
    }
  }

  fullscreen() {
    if (this.videoPlayer && this.videoPlayer.readyState < 4) {
      this.play();
    } else {
      if (!this.isFullscreen) {
        if (this.videoPlayer?.mozRequestFullScreen) {
          this.videoPlayer?.mozRequestFullScreen();
        } else if (this.videoPlayer?.webkitRequestFullscreen) {
          this.videoPlayer?.webkitRequestFullscreen();
        } else if (this.videoPlayer?.webkitEnterFullscreen) {
          this.videoPlayer?.webkitEnterFullscreen();
        } else if (this.videoPlayer?.requestFullscreen) {
          this.videoPlayer?.requestFullscreen();
        }
        this.isFullscreen = true;
      } else {
        if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.cancelFullScreen) {
          document.cancelFullScreen();
        }
        this.isFullscreen = false;
      }
    }
  }

  pictureInPicture() {
    if (this.videoPlayer && this.videoPlayer.readyState < 4) {
      this.play();
    } else {
      if (document.pictureInPictureElement) {
        document.exitPictureInPicture();
      } else if (this.videoPlayer?.requestPictureInPicture) {
        this.videoPlayer?.requestPictureInPicture();
      }
    }
  }

  progressChange(e: MouseEvent) {
    if (this.videoPlayer) {
      const progress = (e.offsetX / (e.target as HTMLElement).offsetWidth) * 100;
      this.videoPlayer.currentTime = (this.duration / 100) * progress;
    }
  }

  formatTime(time: number): string {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time - hours * 3600) / 60);
    const seconds = Math.floor(time - hours * 3600 - minutes * 60);
    return (
      (hours ? (hours < 10 ? '0' : '') + hours + ':' : '') +
      (minutes < 10 ? '0' : '') +
      minutes +
      ':' +
      (seconds < 10 ? '0' : '') +
      seconds
    );
  }

  openSettings() {
    this.showSettings = !this.showSettings;
  }
}
