





















import {Component, Vue} from 'vue-property-decorator';
import Modal from '../Modal.vue';
import DialogBlock from '@/components/DialogBlock.vue';
import BankCardSelect from '@/components/BankCardSelect.vue';
import {vxm} from '@/store';
import Loader from '@/components/Loader.vue';

@Component({
  components: {Loader, BankCardSelect, Modal, DialogBlock},
})
export default class ChoiceCardModal extends Vue {
  selectedCard = '';
  paymentInProgress = false;

  get cards() {
    return vxm.user.userCards;
  }

  mounted() {
    if (this.cards.length) {
      this.selectedCard = this.cards[0].transactionId;
    }
  }

  sendPayment(payWithCard: boolean) {
    if (!this.paymentInProgress) {
      this.$emit('selected', payWithCard ? this.selectedCard : '');
      this.paymentInProgress = payWithCard;
    }
  }

  close() {
    if (!this.paymentInProgress) {
      this.$emit('closed');
    }
  }
}
