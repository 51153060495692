















































import {Component, Mixins, Prop} from 'vue-property-decorator';
import Modal from '../Modal.vue';
import DialogBlock from '@/components/DialogBlock.vue';
import Accordion from '@/components/Accordion.vue';
import DropDown from '@/components/DropDown.vue';
import BankCardSelect from '@/components/BankCardSelect.vue';
import {ModelDataInterface} from '@/types/userDataInterface';
import {vxm} from '@/store';
import CCBillModal from '@/components/modals/CCBillModal.vue';
import ChoiceCardModal from '@/components/modals/ChoiceCardModal.vue';
import CCBillMessagesMixin from '@/mixins/CCBillMessagesMixin';
import DefaultAvatarMixin from '@/mixins/DefaultAvatarMixin';
import GeneralApi from '@/service/generalApi';

@Component({
  components: {ChoiceCardModal, CCBillModal, BankCardSelect, DropDown, Accordion, Modal, DialogBlock},
})
export default class TipsToModel extends Mixins(CCBillMessagesMixin, DefaultAvatarMixin) {
  @Prop() model!: ModelDataInterface;
  @Prop({type: String, default: 'modals'}) portalTo!: string;
  isModelLoaded = false;
  showCCBillModel = false;
  showCardsModal = false;
  isReDirectTo = false;
  selectedCard = '';

  private showDialogBlock = false;
  private tips = '';

  get cards() {
    return vxm.user.userCards;
  }

  mounted() {
    if (this.model) {
      this.isModelLoaded = true;
    }
    if (this.cards.length) {
      this.selectedCard = this.cards[0].transactionId;
    }
  }

  async dialogAction(result) {
    if (!result) {
      this.showDialogBlock = false;
      return;
    }
    if (!this.tipConfirm) {
      const results = await GeneralApi.directaRegionCheck();
      if (results.data.is_redirect_to_directa) {
        const loginUserData = await vxm.user.getMe();
        const nameArray = loginUserData.data.name.split(' ');
        const firstName = nameArray[0];
        const lastName = nameArray.slice(1).join(' ');
        const finalLastName = lastName || firstName;

        const DirectaObj = {
          amount: this.tips.replace('$', ''),
          country: results.data.country_code,
          currency: 'USD',
          photoId: null,
          videoId: null,
          payer: {
            firstName: firstName,
            lastName: finalLastName,
            email: loginUserData.data.email,
          },
          currentPagePath: this.$route.path,
          performerId: this.model._id,
          userId: loginUserData.data._id,
          description: 'Model Tip',
          saleType: 'tip',
        };
        vxm.user
          .directaPayment(DirectaObj)
          .then((res) => {
            window.location.href = res.data.redirect_url;
          })
          .catch((error) => {
            return error;
          });
      } else {
        if (vxm.user.userCards && vxm.user.userCards.length) {
          this.showCardsModal = true;
        } else {
          vxm.user
            .paymentTips({
              amount: this.tips.replace('$', ''),
              performerId: this.model._id,
              provider: 'ccbill',
              // transactionId: this.selectedCard,
            })
            .then(() => {
              // this.showCCBillModel = true;
              setTimeout(() => {
                window.open(vxm.user.CCBillFormUrl, '_blank');
              });
            })
            .catch((error) => {
              return error;
            });
        }
      }

      return;
    }
    this.showDialogBlock = false;
    this.tipConfirm = false;
    this.$emit('closed');
  }

  selectedPayment(id: string) {
    const paymentOptions: any = {
      amount: this.tips.replace('$', ''),
      performerId: this.model._id,
      provider: 'ccbill',
    };
    if (id) {
      paymentOptions.transactionId = id;
    }
    GeneralApi.directaRegionCheck().then((res) => {
      this.isReDirectTo = res.data.is_redirect_to_directa;
    });
    if (this.isReDirectTo) {
      // Directa API call here
    } else {
      vxm.user
        .paymentTips(paymentOptions)
        .then(() => {
          if (!paymentOptions.transactionId) {
            // this.showCCBillModel = true;
            setTimeout(() => {
              window.open(vxm.user.CCBillFormUrl, '_blank');
            });
            this.showCardsModal = false;
          } else {
            window.addEventListener('message', this.receiveMessage);
            this.hiddenIframe = document.createElement('iframe');
            this.hiddenIframe.setAttribute('src', vxm.user.CCBillFormUrl);
            document.body.appendChild(this.hiddenIframe);
          }
        })
        .catch(() => {
          this.showCardsModal = false;
        });
    }
  }

  checkInput(event) {
    const char = event.key.charCodeAt(0);
    const backspace = 66;
    const del = 68;
    const enter = 69;
    const arrows = 65;

    if (char === backspace || char === del || char === enter || char === arrows) {
      return;
    }

    if (char < 48 || char > 57) {
      event.preventDefault();
      return;
    }
  }

  addDollar() {
    const tip = parseInt(this.tips.replace('$', ''), 10);
    if (tip < 1) {
      this.tips = '$1';
    } else if (tip > 100) {
      this.tips = '$100';
    }
    if (this.tips.length === 1 && this.tips[0] === '$') {
      this.tips = '';
      return;
    }
    if (this.tips.length && this.tips[0] !== '$') {
      this.tips = '$' + this.tips;
    }
  }
}
