












import {Component, Prop, Vue} from 'vue-property-decorator';
import Modal from '@/components/Modal.vue';
import Loader from '@/components/Loader.vue';
import VideoForm from '@/components/VideoForm.vue';
import ProgressBar from '@/components/ProgressBar.vue';
import UsersComments from '@/components/UsersComments.vue';

@Component({
  components: {
    UsersComments,
    ProgressBar,
    Modal,
    Loader,
    VideoForm,
  },
})
export default class CommentsModal extends Vue {
  @Prop() readonly mediaItem!: any;
  @Prop() isUserSubscribed!: boolean;

  close() {
    this.$emit('closed');
  }

  like() {
    this.$emit('like');
  }
}
