import {Component, Vue} from 'vue-property-decorator';

@Component
export default class BlobToFileMixin extends Vue {
  dataURLtoFile(dataUrl: string, filename: string) {
    const arr = dataUrl.split(',');
    const mime = (arr[0].match(/:(.*?);/) as Array<string>)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type: mime});
  }
}
