

























import {Component, Vue} from 'vue-property-decorator';
import {vxm} from '@/store';
import Modal from '@/components/Modal.vue';
import Vuelidate from 'vuelidate';
import {email, required} from 'vuelidate/lib/validators';

Vue.use(Vuelidate);

@Component({
  components: {Modal},
  validations: {
    email: {required, email},
  },
})
export default class ForgotPasswordModal extends Vue {
  email = '';
  isConfirm = false;
  isError = false;
  isRequestSend = false;

  send() {
    if (!this.$v.email.$invalid) {
      this.isError = false;
      this.isRequestSend = true;

      vxm.user
        .forgotPassword(this.email)
        .then(() => {
          this.isConfirm = true;
        })
        .catch(() => {
          this.isError = true;
        })
        .then(() => {
          this.isRequestSend = false;
        });
    }
  }

  close() {
    this.$emit('closed');
  }
}
