























































import {Component, Mixins} from 'vue-property-decorator';
import {vxm} from '@/store';
import moment from 'moment';
import MomentDate from '@/mixins/MomentDateMixin';
import {UserSubscriptionsInterface} from '@/types/UserSubscriptionsInterface';
import {ModelDataInterface} from '@/types/userDataInterface';
import Loader from '@/components/Loader.vue';
import {AffiliateContentResponse} from '@/types/AffiliateContentResponse';
import {PhotoInterface} from '@/types/photoInterface';
import {VideoInterface} from '@/types/videoInterface';
import PostCardNew from '@/components/PostCardNew.vue';
// import _ from 'lodash';

@Component({
  components: {Loader, PostCardNew},
})
export default class Subscriptions extends Mixins(MomentDate) {
  subs: UserSubscriptionsInterface[] = [];
  model: ModelDataInterface | {} = {};
  isContentReady = false;
  showModal = false;
  selectedSub: UserSubscriptionsInterface | null = null;
  showContactUs = false;
  isActive = false;
  showNotes = false;
  subModelsCount = 0;

  subParams = {
    page: 1,
    take: 3,
  };

  subDataPosts: Array<AffiliateContentResponse | PhotoInterface | VideoInterface> = [];
  totalSubDataPosts = 0;
  isSubDataRequestSend = false;
  subDataParams = {
    page: 1,
    take: 18,
    isForSale: true,
  };

  mounted() {
    this.getData();
    this.getSubscribedData();
  }

  async getData(params = this.subParams) {
    await this.getSubscriptions(params);
    this.isContentReady = true;
  }

  loadMoreSubsModels() {
    if (this.subModelsCount === 0) {
      return false;
    }
    if (this.subModelsCount > 1) {
      this.subParams.page += 1;
      this.getData();
    }
    return false;
  }

  getSubscriptions(params = this.subParams): UserSubscriptionsInterface[] {
    return vxm.user
      .mySubscriptions(params)
      .then((res) => {
        this.subModelsCount = res.data.items.length;
        this.subs = this.subs.concat(res.data.items);
      })
      .catch((error) => {
        return error;
      });
  }

  loadMoreSubsData() {
    if (this.totalSubDataPosts === 0) {
      return false;
    }
    if (this.totalSubDataPosts > 1) {
      this.subDataParams.page += 1;
      this.getSubscribedData();
    }
    return false;
  }

  getSubscribedData() {
    const params = {...this.subDataParams};
    this.isSubDataRequestSend = true;
    vxm.fan
      .searchSubscribedMedia(params)
      .then((res) => {
        this.totalSubDataPosts = res.data.count;
        this.subDataPosts.push(...res.data.items);
        (this.subDataParams.page as number) += 1;
      })
      .catch((error) => {
        return error;
      })
      .then(() => {
        this.isSubDataRequestSend = false;
      });
  }
}
