

































import {Component, Mixins, Prop} from 'vue-property-decorator';
import Modal from '@/components/Modal.vue';
import SubscriptionDetails from '@/components/modals/ManageSubscriptionModal/SubscriptionDetails.vue';
import PaymentPlan from '@/components/modals/ManageSubscriptionModal/PaymentPlan.vue';
import DialogBlock from '@/components/DialogBlock.vue';
import MomentDate from '@/mixins/MomentDateMixin.ts';
import {vxm} from '@/store';
import CCBillModal from '@/components/modals/CCBillModal.vue';
import {ModelDataInterface} from '@/types/userDataInterface';
import {UserSubscriptionsInterface} from '@/types/UserSubscriptionsInterface';
import ChoiceCardModal from '@/components/modals/ChoiceCardModal.vue';
import moment from 'moment';
import CCBillMessagesMixin from '@/mixins/CCBillMessagesMixin';
import DefaultAvatarMixin from '@/mixins/DefaultAvatarMixin';

@Component({
  components: {ChoiceCardModal, CCBillModal, DialogBlock, PaymentPlan, SubscriptionDetails, Modal},
})
export default class ManageSubscriptionsModal extends Mixins(MomentDate, CCBillMessagesMixin, DefaultAvatarMixin) {
  @Prop() model!: ModelDataInterface;
  @Prop({default: null}) subscriptionData!: UserSubscriptionsInterface | null;
  showPaymentPlan = true;
  showDialogBlock = false;
  showCCBillModel = false;
  showCardsModal = false;
  paymentOptions: any = {
    performerId: this.model._id,
    provider: 'ccbill',
  };

  get defaultCard(): string {
    const card = vxm.user.userCards[0];
    return card ? card.transactionId : '';
  }

  created() {
    if (this.subscriptionData && moment().isBefore(this.subscriptionData.expiredDate)) {
      this.showPaymentPlan = false;
    }
  }

  showPaymentBlock(val) {
    this.showPaymentPlan = val;
  }

  selectPlan(planType: string) {
    if (planType) {
      this.$gtag.event('subscribe button click', {
        // eslint-disable-next-line @typescript-eslint/camelcase
        event_category: planType,
        // eslint-disable-next-line @typescript-eslint/camelcase
        event_label: this.model.name,
      });
      this.paymentOptions.type = planType;
      if (vxm.user.userCards && vxm.user.userCards.length) {
        this.showCardsModal = true;
      } else {
        vxm.user
          .paymentSubscription(this.paymentOptions)
          .then(() => {
            // this.showCCBillModel = true;
            setTimeout(() => {
              window.open(vxm.user.CCBillFormUrl, '_blank');
            });
          })
          .catch((error) => {
            return error;
          });
      }
    } else {
      this.close();
    }
  }

  dialogAction() {
    this.showDialogBlock = false;
  }

  close() {
    this.$emit('closed');
  }

  selectedPayment(id: string) {
    if (id) {
      this.paymentOptions.transactionId = id;
    } else {
      delete this.paymentOptions.transactionId;
    }
    vxm.user
      .paymentSubscription(this.paymentOptions)
      .then(() => {
        if (!this.paymentOptions.transactionId) {
          // this.showCCBillModel = true;
          setTimeout(() => {
            window.open(vxm.user.CCBillFormUrl, '_blank');
          });
          this.showCardsModal = false;
        } else {
          window.addEventListener('message', this.receiveMessage);
          this.hiddenIframe = document.createElement('iframe');
          this.hiddenIframe.setAttribute('src', vxm.user.CCBillFormUrl);
          document.body.appendChild(this.hiddenIframe);
        }
      })
      .catch(() => {
        this.showCardsModal = false;
      });
  }

  paymentSuccess() {
    vxm.user.getSubscriberBills().catch((error) => {
      return error;
    });
    this.$emit('subscribed');
    this.$gtag.event('subscribe', {
      // eslint-disable-next-line @typescript-eslint/camelcase
      event_category: 'success',
      // eslint-disable-next-line @typescript-eslint/camelcase
      event_label: this.model.name,
    });
    this.close();
  }

  paymentFailed() {
    vxm.user.getSubscriberBills().catch((error) => {
      return error;
    });
    this.$emit('subscribed');
    this.$gtag.event('subscribe', {
      // eslint-disable-next-line @typescript-eslint/camelcase
      event_category: 'failed',
      // eslint-disable-next-line @typescript-eslint/camelcase
      event_label: this.model.name,
    });
    this.close();
  }
}
