







import {Component, Vue} from 'vue-property-decorator';
import BecomeModel from '@/components/modals/BecomeModel.vue';
import ModelRegistration from '@/components/modals/ModelRegistration.vue';

@Component({
  components: {
    BecomeModel,
    ModelRegistration,
  },
})
export default class BecomeAModel extends Vue {
  showMenu = false;
  showRegistration = false;

  showModelRegistration() {
    this.$nextTick(() => {
      this.showRegistration = true;
    });
  }
}
