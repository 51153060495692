





















import {Component, Mixins} from 'vue-property-decorator';
import {vxm} from '@/store';
import MomentDateMixin from '@/mixins/MomentDateMixin';

@Component({
  components: {},
})
export default class Dashboard extends Mixins(MomentDateMixin) {
  data = [];
  created() {
    this.loadComments();
  }

  loadComments() {
    vxm.model
      .getAllComments()
      .then((res) => {
        this.data = res.data;
      })
      .catch((error) => {
        return error;
      });
  }
}
