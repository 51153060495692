import {Component, Vue} from 'vue-property-decorator';
import {AffiliateContentResponse} from '@/types/AffiliateContentResponse';
import {VideoInterface} from '@/types/videoInterface';

@Component
export default class VideoPreviewMixin extends Vue {
  timerId;
  imgIndex = 0;

  destroyed() {
    clearTimeout(this.timerId);
  }

  startPreview(item: AffiliateContentResponse | VideoInterface, index = 0) {
    if (item.thumbs) {
      clearTimeout(this.timerId);
      this.imgIndex = 0;
      this.timerId = setTimeout(() => {
        this.nextFrame(item, index);
      }, 1000);
    }
  }

  cancelPreview(item: AffiliateContentResponse | VideoInterface, index = 0) {
    clearTimeout(this.timerId);
    const imgElement = this.getImgElement(index);

    if (!imgElement) {
      return;
    }

    imgElement.setAttribute('src', item.thumbs[0]);
    imgElement.onload = null;
  }

  nextFrame(item: AffiliateContentResponse | VideoInterface, index: number) {
    const src = item.thumbs[item.thumbs.length - 1 > this.imgIndex ? ++this.imgIndex : (this.imgIndex = 0)];
    const imgElement = this.getImgElement(index);

    if (!imgElement) {
      return;
    }

    imgElement.setAttribute('src', src);
    imgElement.onload = () => {
      if (this.timerId) {
        this.timerId = setTimeout(() => {
          this.nextFrame(item, index);
        }, 1000);
      }
    };
  }

  getImgElement(index: number): HTMLImageElement {
    return Array.isArray(this.$refs.previewImg)
      ? (this.$refs.previewImg as HTMLImageElement[])[index]
      : (this.$refs.previewImg as HTMLImageElement);
  }
}
