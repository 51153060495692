






















































import {Component, Prop, Vue} from 'vue-property-decorator';
import Modal from '../Modal.vue';
import Vuelidate from 'vuelidate';
import {email, helpers, minLength, required} from 'vuelidate/lib/validators';
import {vxm} from '@/store';

Vue.use(Vuelidate);

@Component({
  components: {Modal},
  validations: {
    form: {
      message: {
        required,
        minLength: minLength(10),
      },
      name: {
        required,
        minLength: minLength(2),
      },
      email: {
        required,
        email,
      },
      phone: {
        numbers: helpers.regex('numbers', /^[0-9]*$/),
      },
    },
  },
})
export default class ContactUsModal extends Vue {
  @Prop() cancelSubscription!: false;
  responsePending = false;
  form = {
    name: '',
    email: '',
    phone: '',
    message: '',
  };

  fade = false;
  isSend = false;

  mounted() {
    if (vxm.user.token) {
      vxm.user
        .getMe()
        .then((res) => {
          this.form.name = res.data.name;
          this.form.email = res.data.email;
          this.form.phone = res.data.phone;
        })
        .catch((error) => {
          return error;
        });
    }
    if (this.cancelSubscription) {
      this.form.message =
        'My payment was processed by CCBill for Austin Wolf, please cancel my subscription and email me when done. Thank you.';
    }
    this.$nextTick(() => {
      (this.$refs.messageTextarea as HTMLElement).focus();
    });
  }

  sendMessage(data) {
    this.responsePending = true;
    this.$v.$touch();
    if (!this.$v.$invalid) {
      vxm.user
        .contactUs(data)
        .then((res) => {
          if (res.status === 200) {
            this.isSend = true;
          }
        })
        .catch((error) => {
          return error;
        });
    }
  }

  closeModal() {
    this.isSend = false;
    this.$emit('closed');
  }
}
