
























































import {Component, Mixins} from 'vue-property-decorator';
import ManageSubscriptionsModal from '@/components/modals/ManageSubscriptionModal/ManageSubscriptionsModal.vue';
import {vxm} from '@/store';
import moment from 'moment';
import MomentDate from '@/mixins/MomentDateMixin.ts';
import {UserSubscriptionsInterface} from '@/types/UserSubscriptionsInterface.ts';
import {ModelDataInterface} from '@/types/userDataInterface';
import ContactUsModal from '@/components/modals/ContactUsModal.vue';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import Loader from '@/components/Loader.vue';

@Component({
  components: {
    ConfirmationModal,
    ContactUsModal,
    ManageSubscriptionsModal,
    Loader,
  },
})
export default class Subscriptions extends Mixins(MomentDate) {
  subs: UserSubscriptionsInterface[] = [];
  model: ModelDataInterface | {} = {};
  isContentReady = false;
  showModal = false;
  selectedSub: UserSubscriptionsInterface | null = null;
  showContactUs = false;
  isActive = false;
  showNotes = false;
  subsCount = 0;
  subModelsCount = 0;
  subParams = {
    page: 1,
    take: 6,
  };
  filteredSubsModels: UserSubscriptionsInterface[] = [];
  isLoading = false;

  get filteredSubs(): UserSubscriptionsInterface[] {
    return this.subs.filter(
      (subscription) => moment().isSameOrBefore(subscription.expiredDate) && subscription.performer,
    );
    // if (this.isActive) {
    //   return this.subs.filter(
    //     (subscription) => moment().isSameOrBefore(subscription.expiredDate) && subscription.performer,
    //   );
    // } else {
    //   return this.subs.filter(
    //     (subscription) => moment().isSameOrAfter(subscription.expiredDate) && subscription.performer,
    //   );
    // }
  }

  mounted() {
    this.getData();
  }

  async getData(params = this.subParams) {
    // this.isContentReady = true;
    await this.getSubscriptions(params);
  }

  loadMoreSubsModels() {
    if (this.subModelsCount === 0) {
      return false;
    }
    if (this.subModelsCount > 1) {
      this.subParams.page += 1;
      this.getData();
    }
    return false;
  }

  getSubscriptions(params = this.subParams): UserSubscriptionsInterface[] {
    this.isContentReady = true;

    return vxm.user
      .mySubscriptions(params)
      .then((res) => {
        this.subsCount = res.data.count;
        this.subs = this.subs.concat(res.data.items);
        this.subModelsCount = res.data.items.length;
        // this.filteredSubsModels = this.subs.filter(
        //   (subscription) => moment().isSameOrBefore(subscription.expiredDate) && subscription.updatedAt,
        // );
        // return this.filteredSubsModels;
      })
      .catch((error) => {
        return error;
      })
      .finally(() => {
        this.isContentReady = false;
      });
  }

  fDate(date, format) {
    return moment(date).format(format);
  }

  getSubscriptionTime(expiredDate: string) {
    if (moment().isSameOrAfter(expiredDate)) {
      return 'Expired';
    }

    const diff = moment(expiredDate).diff(moment(), 'months');
    if (diff === 0) {
      const days = moment(expiredDate).diff(moment(), 'days') + 1;
      return days !== 1 ? days + ' Days' : days + ' Day';
    }
    if (diff === 1) {
      return '1 Month';
    }
    const result = diff >= 12 ? `${Math.floor(diff / 12)} Year` : `${diff} Month's`;
    return diff >= 24 ? result + `'s` : result;
  }

  async getModel(sub) {
    this.model = await vxm.fan
      .getPerformer(sub.performerId)
      .then((res) => res.data)
      .catch((error) => {
        return error;
      });
    this.selectedSub = sub;
  }

  async cancelSubscription() {
    if (this.selectedSub && this.selectedSub?._id) {
      // eslint-disable-next-line
      (this.model as any) = null;
      this.isLoading = true;
      vxm.fan
        .cancelSubscription(this.selectedSub._id)
        .then(() => {
          this.showNotes = true;
          this.getData();
        })
        .catch((error) => {
          return error;
        })
        .finally(() => {
          this.isLoading = false;
        });
      // if (this.selectedSub?.provider === 'ccbill') {
      //
      // } else {
      //   // this.$nextTick(() => {
      //   //   this.showContactUs = true;
      //   // });
      //   console.log("segpay")
      // }
    }
  }
}
