



















import {Component, Mixins, Prop} from 'vue-property-decorator';
import Modal from '../Modal.vue';
import {vxm} from '@/store';
import {MediaEnum} from '@/types/enums/MediaEnum';
import DropDown from '@/components/DropDown.vue';
import {PostMenuParamsInterface} from '@/types/PostMenuParamsInterface';
import moment from 'moment';
import CopyToClipboardMixin from '@/mixins/CopyToClipboardMixin';

@Component({
  components: {Modal, DropDown},
})
export default class PostMenu extends Mixins(CopyToClipboardMixin) {
  types = MediaEnum;
  targetRoute = '';
  onProfile = false;
  @Prop() readonly post!: PostMenuParamsInterface;
  @Prop() readonly type!: MediaEnum;

  get isLogin(): boolean {
    return !!vxm.user.token;
  }

  get isSubscribed(): boolean {
    return !!vxm.user.subscriptions.items.find(
      (x) => x.performerId === this.post?.modelId && moment(x.expiredDate).isAfter(moment()),
    );
  }

  mounted() {
    let type = '';
    switch (this.type) {
      case MediaEnum.CLIPS:
        type = 'clip';
        break;
      case MediaEnum.PICS:
        type = 'photo';
        break;
      case MediaEnum.PICS_PLUS:
        type = 'photo';
        break;
      default:
        type = 'movie';
        break;
    }
    this.targetRoute = `${window.location.origin}/${type}/${this.post.linkId}`;
    this.onProfile = this.$route.path.split('/')[1] === this.post.username;
  }

  toModelPage(username: string) {
    if (this.$route.name !== 'model-vids') {
      this.$router.push(`/${username}`);
    }
    this.$emit('closed');
  }

  toMessages(data: PostMenuParamsInterface) {
    if (!this.isSubscribed) {
      this.$router.push(`/${data.username}?showSubscribe=true`);
      return;
    }

    vxm.general
      .createMessagesGroups({recipientId: data.modelId, type: 'subscriber'})
      .then((res) => {
        this.$router.push({path: '/fan/messages', query: {chatId: res.data._id}});
      })
      .catch((error) => {
        return error;
      });
  }

  sendATip() {
    if (this.onProfile) {
      this.$emit('sendTip');
      vxm.fan.setModalState({name: 'tips', status: true});
    } else {
      this.$router.push(`/${this.post.username}?showTips=true`);
    }
  }

  tweet(data: PostMenuParamsInterface) {
    const otherWindow: any = window.open();
    otherWindow.opener = null;
    otherWindow.location.href = `https://twitter.com/intent/tweet?text=${data.username},%20${data.description}&url=${this.targetRoute}`;
    this.$emit('closed');
  }
}
