























import {Component, Mixins} from 'vue-property-decorator';
import Modal from '@/components/Modal.vue';
import CCBillForm from '@/components/CCBillForm.vue';
import {vxm} from '@/store';
import Loader from '@/components/Loader.vue';
import NoScrollMixin from '@/mixins/NoScrollMixin';

@Component({
  components: {
    Loader,
    CCBillForm,
    Modal,
  },
})
export default class CCBillModal extends Mixins(NoScrollMixin) {
  showLoader = true;
  showSafariWarning = false;
  isIOS = false;

  created() {
    const ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('safari') != -1 && ua.indexOf('chrome') === -1 && !localStorage.getItem('SafariWarningShown')) {
      this.isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      this.showSafariWarning = true;
    }
  }

  get CCBillLink(): string {
    return vxm.user.CCBillFormUrl;
  }

  paymentSuccess() {
    vxm.user.setCCBill({redirectUrl: '', transactionId: ''});
    this.$emit('paymentSuccess', true);
    this.close();
  }

  paymentFailed() {
    vxm.user.setCCBill({redirectUrl: '', transactionId: ''});
    this.$emit('paymentFailed', false);
    this.close();
  }

  close() {
    this.$emit('closed');
  }

  acceptWarning() {
    this.showSafariWarning = false;
    localStorage.setItem('SafariWarningShown', 'true');
  }
}
