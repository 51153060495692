






























































































































































































import {Component, Mixins, Vue} from 'vue-property-decorator';
import Modal from '@/components/Modal.vue';
import CroppieMixin from '@/mixins/CroppieMixin';
import Vuelidate from 'vuelidate';
import {email, helpers, minLength, required, sameAs} from 'vuelidate/lib/validators';
import BlobToFileMixin from '@/mixins/BlobToFileMixin';
import {vxm} from '@/store';
import {FileModelInterface} from '@/types/fileModelInterface';
import Loader from '@/components/Loader.vue';
import heic2any from 'heic2any';

Vue.use(Vuelidate);

@Component({
  components: {
    Modal,
    Loader,
  },
  validations: {
    form1: {
      name: {required},
      username: {required},
      email: {required, email},
      password: {
        required,
        minLength: minLength(8),
        alpha: helpers.regex('alpha', /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/),
      },
      confirmPassword: {required, sameAs: sameAs('password')},
      sex: {required},
    },
    form3: {
      idImg1: {required},
      // idImg2: {required},
    },
  },
})
export default class ModelRegistration extends Mixins(CroppieMixin, BlobToFileMixin) {
  regStep = 1;
  steps = 3;
  // infant = false;
  captchError = false;
  emailError = false;
  usernameError = false;
  showPass = true;
  showPass2 = true;
  requestSend = false;
  /*
  months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  content_types = ['bisexual', 'straight', 'gay'];
  */
  genders = ['male', 'female', 'MTF', 'FTM'];
  form1 = {
    name: '',
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    sex: '',
  };
  /*
  form2 = {
    photo: '',
    description: '',
  };
  */
  form3: {idImg1: FileModelInterface | null; idImg2: FileModelInterface | null} = {
    idImg1: null,
    idImg2: null,
  };
  idImg1Loading = false;
  idImg2Loading = false;

  /*
  get years() {
    const year = new Date().getFullYear();
    return Array.from({length: year - 1950}, (value, index) => 1951 + index);
  }
  */

  mounted() {
    this.$gtag.event('sign_up', {
      // eslint-disable-next-line @typescript-eslint/camelcase
      event_category: 'model',
      // eslint-disable-next-line @typescript-eslint/camelcase
      event_label: 'start',
    });
  }

  goToPrevStep() {
    if (this.regStep === 1) {
      this.$emit('closeModal');
    }
    if (this.regStep !== 1) {
      this.regStep--;
    }
  }

  stepController() {
    if (this.regStep === 1) {
      this.checkUnique();
      return;
    }

    if (this.regStep === 2) {
      this.submitReg();
    }

    /*
    if (this.regStep === 2 && this.cropBlock) {
      this.crop();
    }

    this.goToNextStep(this.regStep);
    */
  }

  goToNextStep(regStep) {
    const form = 'form' + regStep;
    this.$v[form].$touch();
    if (!this.$v[form].$invalid) {
      if (this.regStep === 2) {
        return;
      }
      this.regStep++;
    } else if (regStep === 2 && this.cropped) {
      this.regStep++;
    }
  }

  onFileChange(e) {
    const files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;
    if (files[0].type === 'image/heic' || (files[0].type === '' && files[0].name.includes('.HEIC'))) {
      this.idImg1Loading = true;
      heic2any({
        blob: files[0],
        toType: 'image/jpeg',
        quality: 0.8,
      }).then((res: any) => {
        const reader = new FileReader();
        reader.readAsDataURL(res);
        reader.onload = (event: any) => {
          this.createImage(this.dataURLtoFile(event.target.result, files[0].name.replace('.HEIC', '.jpg')), e);
        };
      });
    } else {
      this.createImage(files[0], e);
    }
  }

  /*
  detectAge() {
    if (this.form1.dateOfBirth.year && this.form1.dateOfBirth.month && this.form1.dateOfBirth.day) {
      this.infant =
        moment().diff(
          this.form1.dateOfBirth.year +
            '-' +
            (parseInt(this.form1.dateOfBirth.month, 10) < 10 ? '0' : '') +
            this.form1.dateOfBirth.month +
            '-' +
            (parseInt(this.form1.dateOfBirth.day) < 10 ? '0' : '') +
            this.form1.dateOfBirth.day,
          'years',
        ) < 18;
    }
  }
  */

  async createImage(file, e) {
    if (e.target.id === 'file1') {
      this.idImg1Loading = true;
      this.form3.idImg1 = await this.imageIdDirectUpload(file);
      this.idImg1Loading = false;
    } else {
      this.idImg2Loading = true;
      this.form3.idImg2 = await this.imageIdDirectUpload(file);
      this.idImg2Loading = false;
    }
  }

  removeImage(id) {
    id === 'file1' ? (this.form3.idImg1 = null) : (this.form3.idImg2 = null);
  }

  submitReg() {
    this.requestSend = true;
    vxm.user
      .registerModelWithDirectUpload({
        sex: this.form1.sex,
        email: this.form1.email,
        password: this.form1.password,
        name: this.form1.name.trim(),
        username: this.form1.username,
        idImg1: this.form3.idImg1?._id,
        //idImg2: this.form3.idImg2?._id,
      })
      .then(() => {
        this.requestSend = false;
        this.$gtag.event('sign_up', {
          // eslint-disable-next-line @typescript-eslint/camelcase
          event_category: 'model',
          // eslint-disable-next-line @typescript-eslint/camelcase
          event_label: 'complete',
        });
        this.regStep++;
      })
      .catch((error) => {
        this.requestSend = false;
        return error;
      });
  }

  imageIdDirectUpload(file: File) {
    return new Promise<FileModelInterface>((resolve) => {
      vxm.general
        .getPreSignedPostData({
          key: file.name,
          mediaType: file.type,
          type: 'performer.verifyImage',
        })
        .then((res) => {
          const data = new FormData();
          Object.keys(res.data.fields).forEach((key) => {
            data.append(key, res.data.fields[key]);
          });
          data.append('file', file);

          vxm.general
            .directUpload({
              url: res.data.url,
              data,
            })
            .then(() => {
              vxm.user
                .registerModelUploadWithDirectUpload({
                  photoUrl: res.data.fileToken,
                })
                .then((response) => {
                  resolve(response.data);
                })
                .catch((error) => {
                  return error;
                });
            })
            .catch((error) => {
              return error;
            });
        })
        .catch((error) => {
          return error;
        });
    });
  }

  checkUnique() {
    Promise.all([
      vxm.user.checkEmail(this.form1.email).catch(() => {
        this.emailError = true;
        return Promise.reject();
      }),
      vxm.model
        .checkUsername(this.form1.username.trim())
        .then((res) => {
          this.form1.username = res.data.username;
          return Promise.resolve();
        })
        .catch(() => {
          this.usernameError = true;
          return Promise.reject();
        }),
    ]).then(() => {
      this.goToNextStep(this.regStep);
    });
  }

  onBlurEmail() {
    (this.$v.form1 as any).email.$touch();
    this.emailError = false;
  }

  onBlurUsername() {
    (this.$v.form1 as any).username.$touch();
    this.usernameError = false;
  }
}
