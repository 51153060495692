










import {Component, Vue} from 'vue-property-decorator';
import Modal from '@/components/Modal.vue';

@Component({
  components: {
    Modal,
  },
})
export default class ComingSoonModal extends Vue {
  close() {
    this.$emit('closed');
  }
}
