






















































import {Component, Vue} from 'vue-property-decorator';
import {vxm} from '@/store';
import Vuelidate from 'vuelidate';
import {minLength, required, sameAs} from 'vuelidate/lib/validators';
import Modal from '@/components/Modal.vue';

Vue.use(Vuelidate);

@Component({
  components: {Modal},
  validations: {
    form: {
      oldPassword: {
        required,
        minLength: minLength(8),
        regex(value) {
          return /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])/.test(value);
        },
      },
      password: {
        required,
        minLength: minLength(8),
        regex(value) {
          return /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])/.test(value);
        },
      },
      rePassword: {required, sameAs: sameAs('password')},
    },
  },
})
export default class PasswordChangeModal extends Vue {
  showModal = false;
  haveAnError = false;
  showPass = false;
  showPass2 = false;
  showPass3 = false;
  form = {
    oldPassword: '',
    password: '',
    rePassword: '',
  };
  responsePending = false;

  submitForm() {
    this.responsePending = true;
    this.haveAnError = false;
    vxm.user
      .changePassword(this.form)
      .catch((err) => {
        if (err.response.status === 422 && err.response.data.oldPassword == 'Invalid old password.') {
          this.$notify({
            group: 'foo',
            title: `${err.response.data.oldPassword}`,
            type: 'error',
            duration: 5000,
          });
        }
        this.haveAnError = true;
        this.responsePending = false;
      })
      .then(() => {
        this.responsePending = false;
        if (!this.haveAnError) {
          this.$notify({
            group: 'foo',
            type: 'success',
            title: 'Success!',
            text: 'Your password was updated.',
            duration: 5000,
          });
          this.showModal = false;
        }
      });
  }

  openForgotPassword() {
    this.close();
    this.$emit('openForgotPassword');
  }

  close() {
    this.showModal = false;
    this.form.oldPassword = '';
    this.form.password = '';
    this.form.rePassword = '';
    this.$v.$reset();
  }
}
