





import {Component, Mixins, Prop} from 'vue-property-decorator';
import CCBillMessagesMixin from '@/mixins/CCBillMessagesMixin';

@Component
export default class CCBillForm extends Mixins(CCBillMessagesMixin) {
  iframeLoaded = false;
  @Prop(String) readonly CCBillLink!: string;

  mounted() {
    window.addEventListener('message', this.receiveMessage);
  }

  load() {
    this.iframeLoaded = true;
    this.$emit('formLoaded');
  }
}
