





















































import {Component, Mixins} from 'vue-property-decorator';
import Modal from '@/components/Modal.vue';
import {GendersEnum} from '@/types/enums/GendersEnum';
import {store, vxm} from '@/store';
import {email, minLength, required} from 'vuelidate/lib/validators';

import CroppieMixin from '@/mixins/CroppieMixin';
import BlobToFileMixin from '@/mixins/BlobToFileMixin';
import Loader from '@/components/Loader.vue';
import DefaultAvatarMixin from '@/mixins/DefaultAvatarMixin';

@Component({
  components: {Modal, Loader},
  validations: {
    form: {
      email: {required, email},
      name: {required, minLength: minLength(2)},
      sex: {required},
    },
  },
})
export default class ProfileEditModal extends Mixins(CroppieMixin, BlobToFileMixin, DefaultAvatarMixin) {
  get user() {
    return store.getters['user/data'];
  }

  get userP() {
    return vxm.user.data;
  }

  form = {
    name: this.user.name,
    email: this.user.email,
    sex: this.user.sex,
  };

  showModal = false;
  genders = GendersEnum;
  processing = false;
  emailError = false;

  submitForm() {
    this.processing = true;
    this.checkUser().then(() => {
      this.form.name = this.form.name.trim();
      const data = {user: {...this.form, _id: vxm.user.data._id}};
      vxm.user
        .updateProfile(data)
        .then((res) => {
          if (res.status === 200) {
            this.showModal = false;
          }
        })
        .catch((error) => {
          return error;
        })
        .then(() => {
          this.processing = false;
        });
    });
    // this.checkEmail().then(() => {
    // });
  }

  checkUser() {
    if (this.form.name === this.user.name) {
      return Promise.resolve();
    }
    return vxm.user.checkUser(this.form.name).catch(() => {
      this.processing = false;
      return Promise.reject();
    });
  }

  checkEmail() {
    if (this.form.email === this.user.email) {
      return Promise.resolve();
    }

    return vxm.user.checkEmail(this.form.email).catch(() => {
      this.emailError = true;
      this.processing = false;
      return Promise.reject();
    });
  }

  onBlurEmail() {
    (this.$v.form as any).email.$touch();
    this.emailError = false;
  }

  updateAvatar() {
    this.crop().then(() => {
      if (this.croppieImage) {
        const userP = JSON.parse(JSON.stringify(this.userP));
        userP.imageThumbPath = this.croppieImage;
        vxm.user.setUserData(userP);
        const file = this.dataURLtoFile(this.croppieImage, 'avatar');
        vxm.general
          .getPreSignedPostData({
            key: file.name,
            mediaType: file.type,
            type: 'user.avatar',
          })
          .then((res) => {
            const data = new FormData();
            Object.keys(res.data.fields).forEach((key) => {
              data.append(key, res.data.fields[key]);
            });
            data.append('file', file);

            vxm.general
              .directUpload({
                url: res.data.url,
                data,
              })
              .then(() => {
                vxm.user
                  .updateAvatarDirectUpload({
                    photoUrl: res.data.fileToken,
                  })
                  .catch((error) => {
                    return error;
                  });
              })
              .catch((error) => {
                return error;
              });
          })
          .catch((error) => {
            return error;
          });
      }
    });
  }
}
