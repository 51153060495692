












import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class DialogBlock extends Vue {
  @Prop() showContent!: false;

  updated() {
    this.scrollToDialog();
  }

  scrollToDialog() {
    if (!this.showContent) {
      return;
    }
    const element = this.$refs.dialog as Element;
    if (element) {
      element.scrollIntoView();
    }
  }
}
