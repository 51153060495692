


































import {Component, Vue} from 'vue-property-decorator';
import BecomeAModel from '@/components/BecomeAModel.vue';
import ProfileEditModal from '@/components/modals/ProfileEditModal.vue';
import PasswordChangeModal from '@/components/modals/PasswordChangeModal.vue';
import store, {vxm} from '@/store';
import ForgotPasswordModal from '@/components/modals/ForgotPasswordModal.vue';
import ContactUsModalNew from '@/components/modals/ContactUsModalNew.vue';

@Component({
  components: {BecomeAModel, ProfileEditModal, PasswordChangeModal, ForgotPasswordModal, ContactUsModalNew},
})
export default class Details extends Vue {
  havePayment = true;
  isMountedHookReady = false;
  isRequestSend = false;
  showForgotPassword = false;
  showContactUs = false;

  mounted() {
    this.isMountedHookReady = true;
  }

  get isLogin(): boolean {
    return !!vxm.user.token;
  }

  get user() {
    return store.getters['user/data'];
  }

  logout() {
    this.$store.dispatch('resetStore').then(() => {
      localStorage.removeItem('model');
      localStorage.removeItem('fan');
      this.$socket.disconnect();
      this.$router.push('/login');
    });
  }
}
