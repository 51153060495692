

















import {Component, Prop, Vue} from 'vue-property-decorator';
import Modal from '@/components/Modal.vue';

@Component({
  components: {
    Modal,
  },
})
export default class LoginOrRegisterModal extends Vue {
  @Prop({type: Boolean, default: false}) readonly isLogin!: boolean;

  goToLogin(registerType = '') {
    const query = registerType ? `?${registerType}Registration=true` : '';
    this.$router.push(`/login${query}`);
  }
}
