








import {Component, Vue, Prop} from 'vue-property-decorator';

@Component({})
export default class CommentMenu extends Vue {
  @Prop({default: true}) buttonDel!: boolean;

  deleteComment() {
    this.$emit('deleteComment');
  }
}
