
























import {Component, Vue, Prop} from 'vue-property-decorator';
import {ModelDataInterface} from '@/types/userDataInterface';

@Component({})
export default class PaymentPlan extends Vue {
  planType = 'monthly';
  @Prop() model!: ModelDataInterface;
}
