















import ClickOutside from '@/directives/clickOutside';
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  directives: {ClickOutside},
})
export default class BankCardSelect extends Vue {
  @Prop({default: []}) readonly values!: {name: string; cardType: string; transactionId: string}[];
  private selected: {name: string; cardType: string; transactionId: string} = {
    name: '•••• •••• •••• •••',
    cardType: 'mastercard',
    transactionId: '',
  };
  private showSelect = false;

  get selectedCard(): string {
    const card = this.values.find((x) => x.transactionId === this.selected.transactionId);
    return card ? `${card.name.slice(-4)}` : '';
  }
  closeSelect() {
    this.showSelect = false;
  }

  setSelected(value) {
    if (this.selected.transactionId === value.transactionId) {
      this.showSelect = !this.showSelect;
      return;
    }
    this.selected = value;
    this.showSelect = false;
    this.$emit('selected', this.selected.transactionId);
  }

  mounted() {
    if (this.values.length) {
      this.selected = {...this.values[0]};
    }
  }

  creditCardName(card) {
    return card.cardType ? card.cardType.toLowerCase() : 'mastercard';
  }
}
