























import {Component, Prop, Vue} from 'vue-property-decorator';
import Modal from '../Modal.vue';
import {ModelDataInterface} from '@/types/userDataInterface';
import {AffiliateContentResponse} from '@/types/AffiliateContentResponse';
import {VideoInterface} from '@/types/videoInterface';
import {PhotoInterface} from '@/types/photoInterface';
import {MediaEnum} from '@/types/enums/MediaEnum';
import MediaCard from '@/components/MediaCard.vue';
import MediaCardNew from '@/components/MediaCardNew.vue';
import {vxm} from '@/store';

@Component({
  components: {Modal, MediaCard, MediaCardNew},
})
export default class PostDetailsModal extends Vue {
  selectedAlbumImgIndex = 0;
  mediaTypes = MediaEnum;
  mediaItem: AffiliateContentResponse | VideoInterface | PhotoInterface | null = null;
  photos: PhotoInterface[] | string[] = [];
  @Prop(Object) readonly data!: AffiliateContentResponse | VideoInterface | PhotoInterface;
  @Prop() readonly model!: ModelDataInterface;
  @Prop({default: false}) readonly isSubscribed!: boolean;
  @Prop({default: false}) readonly hideMyPhotosHeader!: boolean;
  @Prop({default: false}) readonly hideAvatar!: boolean;

  mounted() {
    this.mediaItem = JSON.parse(JSON.stringify(this.data));
    if (this.mediaItem?.mediaType === this.mediaTypes.PICS || this.mediaItem?.mediaType === this.mediaTypes.PICS_PLUS) {
      (this.mediaItem as PhotoInterface).isForSale ? this.getAlbumGallery() : this.getPhotosGallery(1);
    }
  }

  getAlbumGallery() {
    this.photos = (this.mediaItem as PhotoInterface).thumbnails;
  }

  getPhotosGallery(page: number) {
    vxm.fan
      .getPhotosSearch({performerId: this.model._id, page: page, take: 20, isForSale: false})
      .then((res) => {
        if (page === 1) {
          this.photos = [];
        }
        this.photos.push(...res.data.items);
        if (this.photos.length < res.data.count) {
          this.getPhotosGallery(page + 1);
        }
      })
      .catch((error) => {
        return error;
      });
  }

  close() {
    this.$emit('closed');
  }

  changeImg(direction: number) {
    let index = (this.mediaItem as PhotoInterface).isForSale
      ? this.selectedAlbumImgIndex
      : this.photos.findIndex((x) => x._id === (this.mediaItem as PhotoInterface)._id);
    index =
      index + direction > this.photos.length - 1
        ? 0
        : index + direction < 0
        ? this.photos.length - 1
        : index + direction;
    (this.mediaItem as PhotoInterface).isForSale
      ? (this.selectedAlbumImgIndex = index)
      : this.selectImg(this.photos[index] as PhotoInterface);
  }

  selectImg(img: PhotoInterface) {
    this.mediaItem = img;
    this.selectedAlbumImgIndex = 0;
  }
}
