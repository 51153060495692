import {Component, Vue} from 'vue-property-decorator';
import VueCroppie from 'vue-croppie';
import 'croppie/croppie.css';
import heic2any from 'heic2any';

Vue.use(VueCroppie);

@Component
export default class CroppieMixin extends Vue {
  cropBlock = false;
  croppieImage = '';
  cropped = null;
  heicLoader = false;
  correctSize = false;
  maxWidth = 1920;
  maxHeight = 1080;

  croppie(e) {
    const files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;

    if (typeof files != 'undefined') {
      const readers = new FileReader();

      readers.readAsDataURL(files[0]);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      readers.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = () => {
          const height = image.height;
          const width = image.width;
          if (height > 1080 || width > 1920) {
            alert('Width and Height should not exceed 1920 X 1080 respectively. Try again with small size.');
            return false;
          }
          // alert('Uploaded image has valid Height and Width.');
          this.cropBlock = true;
          if (files[0].type === 'image/heic' || (files[0].type === '' && files[0].name.includes('.HEIC'))) {
            this.heicLoader = true;
            heic2any({
              blob: files[0],
              toType: 'image/jpeg',
              quality: 0.8,
            }).then((res) => {
              const reader = new FileReader();
              reader.readAsDataURL(res as Blob);
              reader.onload = (event: any) => {
                (this.$refs.croppieRef as any).bind({
                  url: event.target.result,
                });
              };
              this.heicLoader = false;
            });
          } else {
            const reader = new FileReader();
            reader.onload = (e: any) => {
              (this.$refs.croppieRef as any).bind({
                url: e.target.result,
              });
            };
            reader.readAsDataURL(files[0]);
          }
        };
      };
    }
  }

  crop() {
    this.cropBlock = false;
    const options = {
      type: 'base64',
      format: 'jpeg',
      circle: false,
    };
    return new Promise<void>((resolve) => {
      (this.$refs.croppieRef as any).result(options, (output) => {
        this.cropped = this.croppieImage = output;
        resolve();
      });
    });
  }
}
