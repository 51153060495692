
























































































import {Component, Mixins, Prop, Vue, Watch} from 'vue-property-decorator';
import DropDown from './DropDown.vue';
import PostMenu from '@/components/modals/PostMenu.vue';
import {VideoInterface} from '@/types/videoInterface';
import MomentDateMixin from '@/mixins/MomentDateMixin';
import CommentsModal from '@/components/modals/CommentsModal.vue';
import VideoPlayer from '@/components/VideoPlayer.vue';
import {ModelDataInterface} from '@/types/userDataInterface';
import {MediaEnum} from '@/types/enums/MediaEnum';
import {PostMenuParamsInterface} from '@/types/PostMenuParamsInterface';
import {vxm} from '@/store';
import CCBillModal from '@/components/modals/CCBillModal.vue';
import ManageSubscriptionsModal from '@/components/modals/ManageSubscriptionModal/ManageSubscriptionsModal.vue';
import Loader from '@/components/Loader.vue';
import ChoiceCardModal from '@/components/modals/ChoiceCardModal.vue';
import CCBillMessagesMixin from '@/mixins/CCBillMessagesMixin';
import NumbersMixin from '@/mixins/NumbersMixin';
import DefaultAvatarMixin from '@/mixins/DefaultAvatarMixin';
import CopyToClipboardMixin from '@/mixins/CopyToClipboardMixin';
import lineClamp from 'vue-line-clamp';
import ComingSoonModal from '@/components/modals/ComingSoonModal.vue';
import {PhotoInterface} from '@/types/photoInterface';
import TipsToModel from '@/components/modals/TipsToModel.vue';

Vue.use(lineClamp);

@Component({
  components: {
    ComingSoonModal,
    ChoiceCardModal,
    Loader,
    ManageSubscriptionsModal,
    CCBillModal,
    CommentsModal,
    PostMenu,
    DropDown,
    VideoPlayer,
    TipsToModel,
  },
})
export default class MediaCard extends Mixins(
  MomentDateMixin,
  CCBillMessagesMixin,
  NumbersMixin,
  DefaultAvatarMixin,
  CopyToClipboardMixin,
) {
  showComments = false;
  mediaTypes = MediaEnum;
  sendingInProcess = false;
  showCCBillModel = false;
  showSubscriptionModal = false;
  showImgLoader = false;
  processing = false;
  showCardsModal = false;
  showComingSoon = false;
  contentIsReady = false;
  tipsToModel = false;
  @Prop() readonly mediaItem!: VideoInterface;
  @Prop() readonly model!: ModelDataInterface;
  @Prop(Boolean) readonly isSubscribed!: boolean;
  @Prop(Boolean) readonly showCarouselControls!: boolean;
  @Prop({default: false}) readonly hideMyPhotosHeader!: boolean;
  @Prop({default: false}) readonly hideAvatar!: boolean;
  @Prop(Number) readonly selectedAlbumImgIndex!: number;

  get isLogin(): boolean {
    return !!vxm.user.token;
  }

  get filterPostMenuData(): PostMenuParamsInterface {
    return {
      username: this.model.username,
      description: this.mediaItem.name || '',
      linkId: this.mediaItem._id,
      modelId: this.model._id,
    };
  }

  get checkVideoAccess(): boolean {
    return (
      (this.mediaItem.mediaType === this.mediaTypes.CLIPS && (this.mediaItem as any).isViewable) ||
      (this.mediaItem.mediaType === this.mediaTypes.VIDS_PLUS && this.mediaItem.isPurchased) ||
      (this.mediaItem.mediaType === this.mediaTypes.VIDS && this.mediaItem.isSubscribed) ||
      (this.mediaItem.mediaType === this.mediaTypes.CLIPS
        ? (this.mediaItem as any).performerInfo._id === vxm.user.data._id
        : this.mediaItem.performer.findIndex((performer: any) => performer._id === vxm.user.data._id) !== -1) ||
      this.isMy
    );
  }

  get isPerformer(): boolean {
    return vxm.user.role === 'performer';
  }

  get isMy(): boolean {
    if (vxm.user.data.role === 'performer') {
      return this.mediaItem.performer.findIndex((x) => x._id === vxm.user.data._id) !== -1;
    }
    return false;
  }

  @Watch('mediaItem.images', {deep: true, immediate: true}) onImgChanged() {
    this.updatePhoto();
  }

  @Watch('selectedAlbumImgIndex') onAlbumIndexChange() {
    this.updatePhoto();
  }

  updatePhoto() {
    if (this.mediaItem.mediaType === MediaEnum.PICS || this.mediaItem.mediaType === MediaEnum.PICS_PLUS) {
      this.showImgLoader = true;
      const img = new Image();
      const self = this as any;
      const mediaItem: PhotoInterface = this.mediaItem as any;
      if (mediaItem.isForSale && mediaItem.isPurchasable && !this.isPerformer) {
        img.src = require(`@/assets/images/logo-login.svg`);
      } else if (mediaItem.images) {
        img.src = mediaItem.images[this.selectedAlbumImgIndex];
      } else {
        img.src = mediaItem.thumbnails[0];
      }
      img.draggable = false;
      img.alt = 'photo';
      img.onload = function() {
        const container = self.$refs.imageContainer as HTMLElement;
        Vue.nextTick(() => {
          container.innerHTML = '';
          container.append(this as HTMLElement);
          self.showImgLoader = false;
        });
      };
    }
  }

  get taggedPerformers() {
    return Array.isArray(this.mediaItem.performer) && this.mediaItem.performer.length && this.model._id
      ? this.mediaItem.performer.filter((x: any) => x._id !== this.model._id)
      : [];
  }

  created() {
    this.isReady();
  }

  showSubscriptionOrComingSoonModal() {
    if (this.model.isPayable) {
      this.showSubscriptionModal = true;
    } else {
      this.showComingSoon = true;
    }
  }

  timeConvert(duration: number): string {
    let min = Math.floor(duration / 60) + '';
    let sec = duration - Math.floor(duration / 60) * 60 + '';
    min = min.length === 1 ? '0' + min : min;
    sec = sec.length === 1 ? '0' + sec : sec;
    return `${min}:${sec}`;
  }

  changeLikes() {
    const api = this.mediaItem.isLikedByMe ? vxm.fan.unlike : vxm.fan.like;
    api({
      entityId: this.mediaItem._id,
      entityType:
        this.mediaItem.mediaType === MediaEnum.PICS || this.mediaItem.mediaType === MediaEnum.PICS_PLUS
          ? 'photo'
          : 'video',
    })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.ok) {
            (this.mediaItem as VideoInterface).isLikedByMe
              ? (this.mediaItem as VideoInterface).likeTotal--
              : (this.mediaItem as VideoInterface).likeTotal++;
            this.$emit('changeLike', (this.mediaItem as VideoInterface).isLikedByMe ? -1 : 1);
            (this.mediaItem as VideoInterface).isLikedByMe = !(this.mediaItem as VideoInterface).isLikedByMe;
          }
        }
      })
      .catch((error) => {
        return error;
      })
      .then(() => {
        this.processing = false;
      });
  }

  share() {
    if (!this.mediaItem.isSubscribed) {
      this.showSubscriptionOrComingSoonModal();
      return;
    }

    if (this.mediaItem?.convertedFiles?.length) {
      this.copyToClipboard(this.mediaItem.convertedFiles[0].path);
    }
  }

  async favorite() {
    if (!vxm.user.token || this.processing) {
      return;
    }
    this.processing = true;
    const api = (this.mediaItem as VideoInterface).isMyFavorite ? vxm.fan.removeFromFavorite : vxm.fan.addToFavorite;
    await api({
      entityId: this.mediaItem._id,
      entityType:
        this.mediaItem.mediaType === MediaEnum.PICS || this.mediaItem.mediaType === MediaEnum.PICS_PLUS
          ? 'photo'
          : 'video',
    })
      .then(() => {
        (this.mediaItem as VideoInterface).isMyFavorite = !(this.mediaItem as VideoInterface).isMyFavorite;
        this.$emit('changeFavorite');
      })
      .catch((error) => {
        return error;
      });
    this.processing = false;
  }

  buyItem() {
    if (this.mediaItem.isPayable) {
      if (vxm.user.userCards && vxm.user.userCards.length) {
        this.showCardsModal = true;
      } else {
        const paymentOptions: any = {
          provider: 'ccbill',
          // transactionId: this.defaultCard,
        };
        const idField = this.mediaItem.mediaType === this.mediaTypes.PICS_PLUS ? 'photoId' : 'videoId';
        const apiName = this.mediaItem.mediaType === this.mediaTypes.PICS_PLUS ? 'paymentPhoto' : 'paymentVideo';
        paymentOptions[idField] = this.mediaItem._id;
        vxm.user[apiName](paymentOptions).then(() => {
          // this.showCCBillModel = true;
          setTimeout(() => {
            window.open(vxm.user.CCBillFormUrl, '_blank');
          });
        });
      }
    } else {
      this.showComingSoon = true;
    }
  }

  setVideoView() {
    vxm.fan.setVideoView(this.mediaItem._id);
  }

  selectedPayment(id: string) {
    const paymentOptions: any = {
      provider: 'ccbill',
    };
    const idField = this.mediaItem.mediaType === this.mediaTypes.PICS_PLUS ? 'photoId' : 'videoId';
    const apiName = this.mediaItem.mediaType === this.mediaTypes.PICS_PLUS ? 'paymentPhoto' : 'paymentVideo';
    paymentOptions[idField] = this.mediaItem._id;
    if (id) {
      paymentOptions.transactionId = id;
    }
    vxm.user[apiName](paymentOptions)
      .then(() => {
        if (!paymentOptions.transactionId) {
          // this.showCCBillModel = true;
          setTimeout(() => {
            window.open(vxm.user.CCBillFormUrl, '_blank');
          });
          this.showCardsModal = false;
        } else {
          window.addEventListener('message', this.receiveMessage);
          this.hiddenIframe = document.createElement('iframe');
          this.hiddenIframe.setAttribute('src', vxm.user.CCBillFormUrl);
          document.body.appendChild(this.hiddenIframe);
        }
      })
      .catch(() => {
        this.showCardsModal = false;
      });
  }

  goToProfile() {
    this.$router.push(`/${this.model.username}`);
  }

  getThisHref() {
    return window.location.origin + '/' + this.model?.username;
  }

  getPostHref() {
    return `${window.location.origin}/movie/${this.filterPostMenuData.linkId}`;
  }

  getPhotoHref() {
    return `${window.location.origin}/photo/${this.filterPostMenuData.linkId}`;
  }

  isReady() {
    this.contentIsReady = true;
    this.$emit('contentIsReady');
  }
}
