
































import {Component, Mixins, Prop} from 'vue-property-decorator';
import Accordion from '@/components/Accordion.vue';
import {ModelDataInterface} from '@/types/userDataInterface';
import moment from 'moment';
import {UserSubscriptionsInterface} from '@/types/UserSubscriptionsInterface';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import {CreditCardsEnum} from '@/types/enums/CreditCardsEnum';
import MomentDateMixin from '@/mixins/MomentDateMixin';

@Component({
  components: {ConfirmationModal, Accordion},
})
export default class SubscriptionDetails extends Mixins(MomentDateMixin) {
  showConfirmation = false;
  @Prop({}) model!: ModelDataInterface;
  @Prop({default: null}) subscriptionData!: UserSubscriptionsInterface | null;

  get isCanBeCanceled() {
    return moment().diff(this.subscriptionData?.createdAt, 'hours') > 24;
  }

  fDate(date, format) {
    return moment(date).format(format);
  }

  modalClosed(confirmed: boolean) {
    this.showConfirmation = false;
    if (confirmed) {
      this.$emit('cancelSubscription');
    }
  }

  creditCards = CreditCardsEnum;

  get creditCardName() {
    return this.subscriptionData?.paymentInformation?.cardType
      ? this.subscriptionData.paymentInformation.cardType.toLowerCase()
      : 'mastercard';
  }

  setUnsubscriptionText(): string {
    if (this.subscriptionData?.isRebillingCancelled) {
      return 'Currently Unsubscribed';
    }
    // if (this.subscriptionData?.provider !== 'ccbill') {
    //   return 'Fill Cancellation form';
    // }
    return this.$t('profile.cancel-sub') as string;
  }
}
