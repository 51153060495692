import {Component, Vue} from 'vue-property-decorator';

@Component
export default class CCBillMessagesMixin extends Vue {
  showCardsModal = false;
  tipConfirm = false;
  hiddenIframe = document.createElement('iframe');
  onSuccessRedirectUrl: string | null = '';

  receiveMessage(event) {
    if (event.data.ok) {
      this.showCardsModal = false;
      this.$emit('paymentSuccess');
      this.paymentSuccess();
      this.tipConfirm = true;
      this.$notify({
        group: 'foo',
        type: 'success',
        title: 'Success!',
        text: 'Thank you for your purchase',
        duration: 5000,
      });
      window.removeEventListener('message', this.receiveMessage);
      if (document.body.contains(this.hiddenIframe)) {
        document.body.removeChild(this.hiddenIframe);
      }

      this.$store.dispatch('paymentSuccess');

      if (this.onSuccessRedirectUrl) {
        this.$router.push(this.onSuccessRedirectUrl, () => {
          this.$notify({
            group: 'foo',
            type: 'success',
            title: 'Success!',
            text: 'Thank you for your purchase',
            duration: 5000,
          });
        });
      }
    }
    if (event.data.error) {
      this.showCardsModal = false;
      this.$emit('paymentFailed');
      this.paymentFailed();
      this.$notify({
        group: 'foo',
        type: 'error',
        title: 'Oops!',
        text: 'Something Went Wrong',
        duration: 5000,
      });
      window.removeEventListener('message', this.receiveMessage);
      if (document.body.contains(this.hiddenIframe)) {
        document.body.removeChild(this.hiddenIframe);
      }
    }
  }
  paymentSuccess() {
    return;
  }
  paymentFailed() {
    return;
  }
}
