






















































import {Component, Mixins, Prop} from 'vue-property-decorator';
import {VEmojiPicker} from 'v-emoji-picker';
import {PhotoInterface} from '@/types/photoInterface';
import {VideoCommentsInterface, VideoInterface} from '@/types/videoInterface';
import {vxm} from '@/store';
import MomentDateMixin from '@/mixins/MomentDateMixin';
import Comments from '@/views/model-views/Comments.vue';
import CommentMenu from '@/components/modals/CommentMenu.vue';
import DropDown from '@/components/DropDown.vue';
import NumbersMixin from '@/mixins/NumbersMixin';
import DefaultAvatarMixin from '@/mixins/DefaultAvatarMixin';
import {MediaEnum} from '@/types/enums/MediaEnum';
import {t} from 'v-emoji-picker/lib/locale';

@Component({
  components: {CommentMenu, Comments, VEmojiPicker, DropDown},
})
export default class UsersComments extends Mixins(MomentDateMixin, NumbersMixin, DefaultAvatarMixin) {
  @Prop() data!: PhotoInterface | VideoInterface;
  @Prop() isUserSubscribed!: boolean;
  @Prop() likes!: {
    total: number;
    me: false;
  };

  get user() {
    return vxm.user.data;
  }

  get isPerformer() {
    return this.user.role === 'performer';
  }

  newComment = '';
  showEmoji = false;
  sendingInProcess = false;
  comments: VideoCommentsInterface[] = [];
  dataIsLoaded = false;

  selectEmoji(emoji) {
    this.newComment = this.newComment + emoji.data;
  }

  created() {
    this.getComments(this.data._id)
      .then((res) => {
        this.comments = res;
      })
      .catch((error) => {
        return error;
      })
      .then(() => {
        this.dataIsLoaded = true;
      });
  }

  getComments(id) {
    return vxm.model
      .getComments({
        id: id,
        type: this.data.mediaType === MediaEnum.PICS_PLUS || this.data.mediaType === MediaEnum.PICS ? 'photo' : 'video',
      })
      .then((res) => {
        return res.data.items;
      })
      .catch((error) => {
        return error;
      });
  }

  sendMessage() {
    if (this.newComment.trim().length < 6) {
      return false;
    }
    this.sendingInProcess = true;
    const data: {content: string; photo?: string; video?: string} = {content: this.newComment};
    this.data.mediaType === MediaEnum.PICS || this.data.mediaType === MediaEnum.PICS_PLUS
      ? (data.photo = this.data._id)
      : (data.video = this.data._id);
    vxm.user
      .sendComment(data)
      .then((res) => {
        this.newComment = '';
        if (res.status === 200) {
          this.comments.unshift(res.data);
        }
      })
      .catch((error) => {
        return error;
      })
      .then(() => {
        this.sendingInProcess = false;
      });
  }

  emitLike() {
    this.$emit('like');
  }

  deleteComment(id, indexInArray) {
    vxm.user
      .deleteComment(id)
      .then((res) => {
        if (res.status === 200) {
          this.comments.splice(indexInArray, 1);
        }
      })
      .catch((error) => {
        return error;
      });
  }

  isMyVideo() {
    if (this.isPerformer) {
      return this.data.user._id === this.user._id;
    }
    return false;
  }

  isAmICoAuthor() {
    if (this.data.performer?.length > 1) {
      return this.data.performer.map((element: any) => element._id).indexOf(this.user._id) !== -1;
    }
    return false;
  }

  goToProfile(data) {
    this.$router.push(`/${data.username}`);
  }

  isMyComment(comment) {
    if (!comment.user) {
      return false;
    }
    return comment.user._id === this.user._id;
  }
}
