



































import {Component, Vue} from 'vue-property-decorator';
import DetailsNew from '@/views/fan-views/profile/DetailsNew.vue';
import PaymentHistoryNew from '@/views/fan-views/profile/PaymentHistoryNew.vue';
import SubscriptionsNew from '@/views/fan-views/profile/SubscriptionsNew.vue';
import BecomeAModel from '@/components/BecomeAModel.vue';
import store, {vxm} from '@/store';

@Component({
  components: {
    DetailsNew,
    PaymentHistoryNew,
    SubscriptionsNew,
    BecomeAModel,
  },
})
export default class Account extends Vue {
  isRequestSend = false;

  get user() {
    return store.getters['user/data'];
  }

  get sendSalesMarketingEmails() {
    return this.user && this.user.settings ? this.user.settings.sendSalesMarketingEmails : false;
  }

  sendMarketingEmailsOn() {
    this.updateProfile({settings: {sendSalesMarketingEmails: true}});
  }

  toggleSalesMarketingEmails() {
    this.updateProfile({settings: {sendSalesMarketingEmails: false}});
  }

  updateProfile(fields) {
    if (!this.isRequestSend) {
      this.isRequestSend = true;
      const data = {user: fields};
      data.user._id = this.user._id;
      vxm.user
        .updateProfile(data)
        .then((res) => {
          if (res.status === 200) {
            this.$notify({
              group: 'foo',
              type: 'success',
              title: 'Success!',
              text: 'Information was updated',
              duration: 5000,
            });
          }
        })
        .catch((error) => {
          return error;
        })
        .then(() => {
          this.isRequestSend = false;
        });
    }
  }
}
