import {Component, Vue} from 'vue-property-decorator';

@Component
export default class CopyToClipboardMixin extends Vue {
  copyToClipboard(linkToCopy: string) {
    const dummy = document.createElement('textarea');
    document.body.appendChild(dummy);
    dummy.value = linkToCopy;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
    this.$notify({
      group: 'foo',
      type: 'success',
      title: 'Copied!',
      duration: 2000,
      text: 'Data was copied to clipboard',
    });
  }
}
